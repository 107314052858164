import React from "react";
import CardPortfolio from "./card/card";
import Present from "./presentation/present";


const Portefolio=()=>{

      return(

        <>
        
        <Present/>
         
        </>
      )


}


export  default Portefolio